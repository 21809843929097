import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import PageBanner from "@/components/page-banner";
import Cricket from "@/components/cricket";
import Footer from "@/components/footer-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
// import CallToActionOne from "@/components/call-to-action-one";

const CricketPage = () => { 
    return (
        <MenuContextProvider>
            <SearchContextProvider>
                <Layout PageTitle="Cricket">
                    <HeaderOne/>
                    <PageBanner title="Cricket" name="Cricket" />
                    <Cricket />
                    {/* <CallToActionOne /> */}
                    <Footer/>
                </Layout>
            </SearchContextProvider>
        </MenuContextProvider>
    );
};

export default CricketPage