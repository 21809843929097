import React, { Fragment } from "react"

const SectionTitle = ({ data }) => {
  const { title, subTitle } = data
  return (
    <Fragment>
      <h2 className="sec_title">{title}</h2>
      <h3 className="chooseUs_title">{subTitle}</h3>
      {/* <p className="sec_desc">{text}</p> */}
    </Fragment>
  )
}

export default SectionTitle
