import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import { DataEntryAppData } from './data'
import FeatureSection from "@/components/feature-section"

const DataEntryApp = () => {

    const { posts } = DataEntryAppData

    return (
        <section>
            <Container className="commonSection" id="data-entry-tab">
                <Row>
                    <Col>
                        {posts.map((post, i) => {
                            return (
                                <Col className="data-entry-content" key={i}>
                                    <FeatureSection className='img-fluid' data={post} pos={i+1} img_resl={'data-entry-app-img'}/>
                                </ Col>
                            )
                        })}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default DataEntryApp