import MatchListing from '@/images/data_entry_app/match_listing.png'
import MatchLanding from '@/images/data_entry_app/match_landing.png'
import PickpPlaying11 from '@/images/data_entry_app/pick_playing_11.png'
import PickpPlayingSquad from '@/images/data_entry_app/pick_playing_squad.png'
import TossUpdate from '@/images/data_entry_app/toss_update.png'
import ScoreEntryScreen from '@/images/data_entry_app/score_entry_screen.png'
import SelectingPlayer from '@/images/data_entry_app/selecting_player.png'
import SecondInnningsDataEntry from '@/images/data_entry_app/second_innings_data_entry.png'
import FOWWickets from '@/images/data_entry_app/fow_wickets.png'
import ExtraDataEntry from '@/images/data_entry_app/extra_data_entry.png'
import EOI from '@/images/data_entry_app/eoi.png'
import MatchCompleted from '@/images/data_entry_app/match_completed.png'
import PoM from '@/images/data_entry_app/POM.png'
import MoreOptions from '@/images/data_entry_app/more_options.png'
import QuickOver from '@/images/data_entry_app/quick_over.png'
import OverAdjustments from '@/images/data_entry_app/over_adjustments.png'
import EndOfInnings from '@/images/data_entry_app/end_of_innings.png'
import RetirePlayer from '@/images/data_entry_app/retire_a_player.png'

export const DataEntryAppData = {
    posts: [
        {   
            sectionContent: {
                title:'Listing Page',
                subTitle: '',
                text: 'The matches that are scheduled on the Scoreblox HQ portal appears in the Future tab for the Scorer to start the game. Only allocated matches are listed for a given user and it is configurable.',
                image: MatchListing,
            }
        },
        {   
            sectionContent: {
                title:'Landing Page',
                subTitle: '',
                text: 'Scorer views the landing page where the Venue of the match is displayed. Set Players action is initiated from this screen, which moves the match to LIVE state once configured.',
                image: MatchLanding,
            }
        },
        {   
            sectionContent: {
                title:'Pick Playing Squad',
                subTitle: '',
                text: 'The Scorer picks the players, based on the match limit, for each of the teams. To fast track the match start a minimum of two players can be picked from each team and proceed to the toss. Later on other players can be added on a needed basis.',
                image: PickpPlaying11,
            }
        },
        {   
            sectionContent: {
                title:'Playing Squad',
                subTitle: '',
                text: 'Once the Playing Squads for both the teams are selected, the Scorer then moves on to the ‘Toss Info’ to select the first Batting team.',
                image: PickpPlayingSquad,
            }
        },
        {   
            sectionContent: {
                title:'Toss Update',
                subTitle: '',
                text: 'Here the scorer selects the Team that won the Toss and the Team that has been assigned to Bat first. Once done, we move on to the actual LIVE Score Entry screen.',
                image: TossUpdate,
            }
        },
        {   
            sectionContent: {
                title:'Score Entry Screen',
                subTitle: '',
                text: 'User has to select the first pair of Batters and the Bowler for the game to start entering the Scores. Ensure the ‘Feeds’ toggle is turned ON for the scores to be in LIVE sync with the Viewer App (via the Scorer HQ platform). Users start entering the scores using the defined preset single click buttons.',
                image: ScoreEntryScreen,
            }
        },
        {   
            sectionContent: {
                title:'Selecting a Player',
                subTitle: '',
                text: 'User selects the Batters or the Bowler using the dropdown option.',
                image: SelectingPlayer,
            }
        },
        {   
            sectionContent: {
                title:'2nd INNINGS DATA ENTRY',
                subTitle: '',
                text: 'The Data entry screen displays the target and the win ratio for easy assessment of the match situation.',
                image: SecondInnningsDataEntry,
            }
        },
        {   
            sectionContent: {
                title:'Fall of Wickets',
                subTitle: '',
                text: 'The admin user can select the type of wicket fallen and have further options to select the player who assisted in the wicket taking ball, also mark the player as sub if he/she was one and assign the runs scored just before the player got out.',
                image: FOWWickets,
            }
        },
        {   
            sectionContent: {
                title:'Extras Data Entry',
                subTitle: '',
                text: 'Extras are covered using these screen options. Admin User can select the type of Extras and the Runs scored and if a Wicket has fallen that can also be marked. All possible types of Extras are covered in this feature.',
                image: ExtraDataEntry,
            }
        },
        {   
            sectionContent: {
                title:'End of Innings',
                subTitle: '',
                text: 'The admin user can End the Innings once the first batting team finishes their allotted overs or they get completely bowled out before the overs get done. The Admin saves the first innings and then gets ready for the 2nd team to start.',
                image: EOI,
            }
        },
        {   
            sectionContent: {
                title:'Match Completed',
                subTitle: '',
                text: 'Once the game is done, the User then selects the Winning Team and the Player of the Match and can add special Notes on the Match.',
                image: MatchCompleted,
            }
        },
        {   
            sectionContent: {
                title:'Player of the Match Screen',
                subTitle: '',
                text: 'The past match listing will display the PoM and the Summary of the game. A quick glance of the completed games are seen in this listing.',
                image: PoM,
            }
        },
        {   
            sectionContent: {
                title:'‘More’ options',
                subTitle: '',
                text: 'Quick short-cut options provided on the score entry screen for the User to make any quick changes / updates.',
                image: MoreOptions,
            }
        },
        // {   
        //     sectionContent: {
        //         title:'Quick Over',
        //         subTitle: '',
        //         text: 'The option lets you update an over in a jiffy just incase you missed out or got slow on the entry side. You can quickly enter the runs scored for each ball or mark it as a Maiden over using the toggle slider.',
        //         image: QuickOver,
        //     }
        // },
        {   
            sectionContent: {
                title:'Over Adjustments',
                subTitle: '',
                text: 'Allows the User to make quick over adjustments, in the cases where the game has been shortened due to bad weather or the target has been revised following a DLS method.',
                image: OverAdjustments,
            }
        },
        {   
            sectionContent: {
                title:'Quick End of Innings',
                subTitle: '',
                text: 'The option lets you quickly End the match or an innings of a Match, or declare the Match as Tied or extend the game into a SuperOver contest.',
                image: EndOfInnings,
            }
        },
        {   
            sectionContent: {
                title:'Retire a Player',
                subTitle: '',
                text: 'Allows the User to retire a player along with mentioning the reason and also update with the Substitute player that will play instead. Various options and combinations are possible as per the game.',
                image: RetirePlayer,
            }
        },
    ]
}