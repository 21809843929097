import React from "react"
import AppsStoreIcon from "../images/appstore.svg"
import PlayStoreIcon from "../images/playstore.svg"
import AppsStoreIconEntry from "../images/appstore-entry.svg"
import PlayStoreIconEntry from "../images/playstore-entry.svg"


const CallToActionOne = ({ extraClassName, buttonClass, label }) => {
  return (
    <section className={`commonSection bg-red-cust ${extraClassName}`}>
      <div id="download-app"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-8 col-md-6">
            <h2 className="cust_sec_title">Download our App Now!</h2>
          </div>
          <div className="col-lg-3  col-sm-2 col-md-3 text-right">
            {label !== undefined ? <a
                onClick={e => {
                  window.open(process.env.GATSBY_IOS_DATA_ENTRY_STOREURL)
                }}
                target="_blank"
              >
                <img
                  src={AppsStoreIconEntry}
                  alt="appstore"
                  className="app-btn-inner"
                /> 
              </a> : <a
                onClick={e => {
                  window.open(process.env.GATSBY_IOS_STOREURL)
                }}
                target="_blank"
              >
                <img 
                  src={AppsStoreIcon}
                  alt="appstore"
                  className="app-btn-inner"
                />
              </a>
            }
          </div>
          <div className="col-lg-3  col-sm-2 col-md-3 text-right">
          {label !== undefined ? <a
              onClick={e => {
                window.open(process.env.GATSBY_ANDROID_DATA_ENTRY_STOREURL)
              }}
              target="_blank"
            >
              <img
                src={PlayStoreIconEntry}
                alt="playstore"
                className="play-btn-inner"
              />
            </a> : <a
              onClick={e => {
                window.open(process.env.GATSBY_ANDROID_STOREURL)
              }}
              target="_blank"
            >
              <img
                src={PlayStoreIcon}
                alt="playstore"
                className="play-btn-inner"
              />
            </a>
          }
          </div>
        </div>
      </div>
    </section>
  )
}

export default CallToActionOne
