import React from "react"
//import { Col, Container, Row } from "react-bootstrap";
import FeatureSection from "@/components/feature-section"

import {
  FeatureData01,
  FeatureData02,
  FeatureData03,
  FeatureData04,
  FeatureData05,
  FeatureData06,
  FeatureData07,
  FeatureData08,
} from "./data"

const CricScoreFeature = () => {
  // pos 0 = left, 1 = right
  return (
    <div>
      <FeatureSection data={FeatureData01} pos={1} />
      <FeatureSection data={FeatureData02} pos={2} />
      <FeatureSection data={FeatureData03} pos={3} />
      <FeatureSection data={FeatureData04} pos={4} />
      <FeatureSection data={FeatureData05} pos={5} />
      <FeatureSection data={FeatureData06} pos={6} />
      <FeatureSection data={FeatureData07} pos={7} />
      <FeatureSection data={FeatureData08} pos={8} />
    </div>

    // <section className="commonSection chooseUs">
    //     <Container>
    //         <Row className={ `${ 0 === left % 2 ? " " : "flex-lg-row-reverse" }` }>
    //             <Col>One</Col>
    //             <Col>Two</Col>
    //         </Row>
    //     </Container>
    // </section>
  )
}

export default CricScoreFeature
