import React from "react"

import FeatureSection from "@/components/feature-section"

import {
  AppFeatureData01,
  AppFeatureData02,
  AppFeatureData03,
  AppFeatureData04,
  AppFeatureData05,
} from "./data"

const CricAppFeature = () => {
  return (
    <div id="score-viewer-app">
      <FeatureSection data={AppFeatureData01} pos={1} />
      <FeatureSection data={AppFeatureData02} pos={2} />
      <FeatureSection data={AppFeatureData03} pos={3} />
      <FeatureSection data={AppFeatureData04} pos={4} />
      <FeatureSection data={AppFeatureData05} pos={5} />
    </div>
  )
}

export default CricAppFeature
