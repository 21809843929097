import React, { Fragment } from "react";

const SectionContent = ({ data }) => {
    const { content } = data;
    return (
        <Fragment>
            <p className="content-sec content-sec-font">{content}</p>
        </Fragment>
    );
};

export default SectionContent;