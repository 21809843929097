import Feature01 from "@/images/home_1/cric-score-entry-img-2.png"
import Feature02 from "@/images/home_1/cric-score-entry-img-3.png"
import Feature03 from "@/images/home_1/cric-score-entry-img-4.png"
import Feature04 from "@/images/home_1/cric-score-entry-img-5.png"
import Feature05 from "@/images/home_1/cric-score-entry-img-6.png"
import Feature06 from "@/images/home_1/cric-score-entry-img-7.png"
import Feature07 from "@/images/home_1/cric-score-entry-img-8.png"
import Feature08 from "@/images/home_1/cric-score-entry-img-9.png"

export const FeatureData01 = {
  sectionContent: {
    secTitle: "ultra-modern",
    title: "Match timeline view",
    subTitle: "with an ultra-modern look",
    text: "During LIVE matches, the recent overs are outlined as an easy-to-digest timeline view. A full timeline view with filterering for 4s and 6s, wickets and by select players are available from the Scorecards menu.",
    image: Feature01,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature01],
}

export const FeatureData02 = {
  sectionContent: {
    secTitle: "Event Stats",
    title: "Event Stats",
    subTitle: "in sync all along",
    text: "Relevant event statics such as most runs, most wickets and toppers in batting and bowling are kept in sync at all times. All these views are mobile-optimised and responsive for desktop devices.",
    image: Feature02,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature02],
}

export const FeatureData03 = {
  sectionContent: {
    secTitle: "Full-fledged tournament management",
    title: "Full-fledged tournament management",
    subTitle: "for cricket matches",
    text: "Create any number of events - tournaments and series, manage associated venues, teams, players and schedules. Event stats are automatically created and kept in sync as the matches progress.",
    image: Feature03,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature03],
}

export const FeatureData04 = {
  sectionContent: {
    secTitle: "Teams and Players",
    title: "Manage Teams and Players",
    subTitle: "with easy allocation",
    text: "Easy to setup teams and players and allocate them to various matches and events. ",
    image: Feature04,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature04],
}

export const FeatureData05 = {
  sectionContent: {
    secTitle: "Simple login, flexible roles",
    title: "Simple login, flexible roles",
    subTitle: "admin, scorer, commentator & auditor",
    text: "It is super easy to sign up with your Google or Apple id and get started on your cricket scoring journey for your club or organization. You can invite others as Admin, Scorer, Commentator or Auditor based on the needs and there are no limits on user counts.",
    image: Feature05,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature05],
}

export const FeatureData06 = {
  sectionContent: {
    secTitle: "Highly Customisable",
    title: "Highly Customisable",
    subTitle: "all across the spectrum",
    text: "Rich dashboard views, stats, news and notifications help the clubs/organizations reach their loyal fans with to-the-minute LIVE score updates delivered to scoreblox mobile apps. For quick access to the LIVE scores, QR code for each match and event can be printed or shared via social media.",
    image: Feature06,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature06],
}

export const FeatureData07 = {
  sectionContent: {
    secTitle: "Match Info",
    title: "Detailed Match Information",
    subTitle: "Toss, Venue, Player of The Match",
    text: "Match detailed information presented in a neat stack for an easy preview of the game.",
    image: Feature07,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature07],
}

export const FeatureData08 = {
  sectionContent: {
    secTitle: "Detailed Scorecard ",
    title: "Detailed Scorecard ",
    subTitle: "with player avatars",
    text: "A full summary of the Scoreboard details",
    image: Feature08,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature08],
}
