import featureTab01 from "@/images/home_1/cric-score-entry-img-1.png"
import featureTab02 from "@/images/home_1/cric-score-app-main.png"
import featureTab03 from "@/images/home_1/data_entry.png"

export const CricketData = {
  posts: [
    {
      secTitle: "Scoring Platform",
      type: 'scoring',
      title: "LIVE Score Entry",
      subTitle: "made as easy as 1-2-3",
      text: "Scoring cricket maches LIVE or after the fact, is such a pleasant experience with scoreblox that you and your scorers will enjoy it on desktops, mobile with its responsive layouts.",
      content:
        "Scoring cricket maches LIVE or after the fact, is such a pleasant experience with scoreblox that you and your scorers will enjoy it both on desktops and mobiles with their responsive layouts. ",
      contentSecond: "",
      image: featureTab01,
      items: [
        {
          title: "Self-contained, state-of-the-art time clock",
        },
        {
          title: "Scalability of up to 500 employees per time clock",
        },
        {
          title: "The ability to connect up to 32 time clocks",
        },
        {
          title: "Employee self-enrollment",
        },
        {
          title: "Payroll integration",
        },
        {
          title: "Built-in backup camera to verify failed punches",
        },
      ],
    },
    {
      secTitle: "Viewer App",
      type: 'viewer',
      title: "LIVE Score App",
      subTitle: "never miss a ball",
      text: "Real time ball by ball scores from all your favorite teams and events right on your mobile device. A unique score viewing experince that providers to the minute scores and stats of your interested matches. Scan a QR code to get to the LIVE details directly. Or setup a widget to get updates on your home screen on both iOS and Android phones/tables. ",
      content:
        "Real time ball by ball scores from all your favorite teams and events right on your mobile device. A unique score viewing experince that providers to the minute scores and stats of your interested matches. Scan a QR code to get to the LIVE details directly. Or setup a widget to get updates on your home screen on both iOS and Android phones/tables. ",
      contentSecond: "",
      image: featureTab02,
      items: [
        {
          title: "Arc scores",
        },
        {
          title: "Simple worm and manhattan charts",
        },
        {
          title: "Easy to use partnership details",
        },
        {
          title: "Last wicket fall info",
        },
        {
          title: "Next to bat players",
        },
        {
          title: "At-a-glance info",
        },
      ],
    },
    {
      secTitle: "Data entry App",
      type: 'entry',
      title: "Live Score Data entry App",
      subTitle: "",
      text: "",
      content: "Scoreblox is a cloud based platform for managing sporting events and LIVE scores, along with a host of other interesting features like live video streaming, predictions etc. As a companion to the web based scoring platform, we’ve built a full featured mobile app for LIVE scoring of various types cricket matches from anywhere in the world. LIVE cricket scores entered via this app is available in real time to you or your club members and friends in addition to stats and viewable in various channels like mobile app, web and more. ",
      contentSecond: "LIVE Cricket scoring app works both online and off-line so that the match score can be captured uninterrupted. Scoring view has all relevant actions right at your finger tip and guides the scorer to a flawless score entry experience. In case there are any corrections needed, it can be easily done with features like undo last ball, edit team and player scores and more.",
      image: featureTab03,
      items: [
        {
          
        },
      ],
    },
  ],
}
