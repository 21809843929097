import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import AOS from "aos"
import "aos/dist/aos.css"
import { useLocation } from "@reach/router";

import CallToActionOne from "@/components/call-to-action-one";
import SectionTitle from "@/components/section-title"
import SectionContent from "@/components/section-content"
import CricScoreFeature from "@/components/cric-score-feat"
import CricAppFeature from "@/components/cric-app-feat"
import DataEntryApp from "../data-entry-app"

import { CricketData } from "./data"

const Cricket = () => {

  const location = useLocation()  

  useEffect(() => {
    AOS.init({
      once: true,
    })
    AOS.refresh()
  }, [])
  const [pageLoaded, setPageLoaded] = useState(false);
  const [active, setActive] = useState(0)
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  useEffect(() => {
    if(pageLoaded)
    window.scrollTo({ top: 250, behavior: 'smooth' });
  }, [activeTab]);
  
  //const { scoreContent, appContent, posts } = CricketData;
  const { posts } = CricketData

  useEffect(() => {
    if(location.hash === "#data-entry-tab") {
    setActive(2)
    setActiveTab(2)
    }
    if(location.hash === "#score-viewer-app") {
    setActive(1)
    setActiveTab(1)
    }

  }, []);

  return (
    <section className="commonSection chooseUs">
      <Container>
        <div id="tabs">
          <Row className="sticky-tab">
            <Col lg={12} md={12} sm={6} className="tab-border cricket-tab">
              <ul className="chooseUs_title">
                {posts.map(({ secTitle }, index) => (
                  <li
                    key={index}
                    className={`${active === index ? "active" : " "}`}
                  >
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault()
                        setActive(index)
                        setActiveTab(index)
                      }}
                    >
                      {secTitle}
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <div className="commonSection">
            {posts.map((post, index) => {
              return index === active ? (
                <div
                  className="tab-pane fade show active animated fadeIn"
                  id={`tes_tab_${index}`}
                  key={index}
                >
                  <Row className="">
                    <Col lg={12} className="text-center">
                      <SectionTitle data={post} />
                      {post.type === 'scoring' ? <button
                          className="sign-up-btn"
                          type="submit"
                          id="con_submit"
                        >
                          <a
                            href={process.env.GATSBY_HQ_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>Sign up FREE</span>
                          </a>
                        </button>
                      : '' 
                      }
                      {post.type === 'viewer' ? <button
                          className="sign-up-btn"
                          type="submit"
                          id="con_submit"
                          to="#download-app"
                        >
                          <Link to="#download-app">
                            <span>Download App</span>
                          </Link>
                        </button>
                      : '' 
                      }
                      {post.type === 'entry' ? <button
                          className="sign-up-btn"
                          type="submit"
                          id="con_submit"
                        >
                          <Link to="#download-app">
                            <span>Download App</span>
                          </Link>  
                        </button>
                      : '' 
                      }
                      <SectionContent data={post} />
                    </Col>
                  </Row>
                  <Row
                    className={`${
                      0 === index % 2 ? " " : "flex-lg-row-reverse cust-sec-pad"
                    }`}
                  >
                    <Col lg={12} data-aos="fade-up">
                      <div className="chose_img">
                        <img
                          src={post.image}
                          alt={`chose_img-${index}`}
                          className={`features-img-centre ${post.type === 'entry' ? 'data-entry-image' : ''}`}
                        />
                      </div>
                    </Col>
                  </Row>
                  {post.type === 'entry' ? <p className="content-sec content-sec-font">{post.contentSecond}</p> : ''}
                  {post.type === 'scoring' ? <CricScoreFeature /> : ''}
                  {post.type === 'viewer' ? <CricAppFeature /> : ''}
                  {post.type === 'entry' ? <DataEntryApp /> : ''}
                </div>
              ) : null
            })}
          </div>
        </div>
      </Container>
      {posts.map((post, index) => {
        return index === active ? (
          post.type === 'entry' ? <CallToActionOne label={'SCORE ENTRY APP'} /> : <CallToActionOne/>
        ) : null
      })}
    </section>
  )
}

export default Cricket
