import Feature01 from "@/images/home_1/cric-score-app-img-1.png"
import Feature02 from "@/images/home_1/cric-score-app-img-2.png"
import Feature03 from "@/images/home_1/cric-score-app-img-3.png"
import Feature04 from "@/images/home_1/cric-score-app-img-4.png"
import Feature05 from "@/images/home_1/cric-score-app-img-5.png"

export const AppFeatureData01 = {
  sectionContent: {
    secTitle: "International and Domestic matches",
    title: "International and Domestic matches",
    subTitle: "your local teams are never left alone",
    text: "Popular international matches are always part of scoreblox live scores app. And now any domestic local match covered world over by any club or organization is searchable in scoreblox and can be bookmarked for LIVE score details and other interesting match stats.",
    image: Feature01,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature01],
}

export const AppFeatureData02 = {
  sectionContent: {
    secTitle: "unique LIVE Scores view",
    title: "unique LIVE Scores view",
    subTitle: "partnerships, projections and equations",
    text: "scoreblox takes a unique visualization approach for the LIVE cricket scores on mobiles and tablets. Feature rich yet simple to use and enjoy LIVE scores from your favorite teams and events. Projections, Equation and Next to bat/Last wicket are easily accessible form the LIVE scores screen anytime. And charts are a tap away, so is the detailed scorecards and partnership data.",
    image: Feature02,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature02],
}

export const AppFeatureData03 = {
  sectionContent: {
    secTitle: "Ultra modern match timeline view",
    title: "Super cool Match scorecard",
    subTitle: "scoreboard, parternships and timeline view",
    text: "During LIVE matches, the recent overs are outlined as an easy-to-digest timeline view. A full timeline view with filterering for 4s and 6s, Wickets and by select players are available from Scorecards menu.",
    image: Feature03,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature03],
}

export const AppFeatureData04 = {
  sectionContent: {
    secTitle: "Event stats and news",
    title: "Event stats and news",
    subTitle: "toppers, best performances in-sync",
    text: "Global events, and the details statistics such as top run getters, top wicket takers and best performances are easy to navigate to. Match and event news as provided by relevant clubs are both available and bookmarkable for future reading.",
    image: Feature04,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature04],
}

export const AppFeatureData05 = {
  sectionContent: {
    secTitle: "Customise by Logging in",
    title: "Customise by Logging in",
    subTitle: "with your favourite identities",
    text: "Login with your google or apple ID to configure notification settings, favourite teams and events. View recently viewed matches, book mark matches and news for later. And much much more. ",
    image: Feature05,
  },
  button: {
    label: "Learn More",
    url: "#",
  },
  gallery: [Feature05],
}
